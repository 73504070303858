:root {
    --baseColor: rgba(28, 27, 29, 1);
    --white: #ffffff;
  }
.custom-shape-divider-bottom-1679186144 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
   z-index: -1;
   
}

.custom-shape-divider-bottom-1679186144 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    transform: rotate(180deg) translate(1px,2px);
    
    height: 102px;
}

.custom-shape-divider-bottom-1679186144 .shape-fill {
    fill: var(--baseColor);
}

.custome_shape {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg) translate(0px,-1px);;
    z-index: -1;
}

.custome_shape svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 160px;
}

.custome_shape .shape-fill {
    fill: var(--baseColor);
}
body{
    background-color:rgb(143 143 142);
}
.logo {
    height:120px;
     width:120px;
    
}
.poza1
{
   border-radius:  32% 68% 70% 30% / 30% 30% 70% 70% ;
   width: 600px;
   height: 600px;
   border:solid var(--baseColor) 5px; 
}
.textLink{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: bold;
    font-size: 20px;
}
.descriere
{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 50px;
}
.headerDivider{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: visible;
    line-height: 0;
    transform: rotate(180deg) translate(0px,1px);
    z-index: -1;
}

.headerDivider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 65px;
}

.headerDivider .shape-fill {
    fill: var(--baseColor);
}
@media screen and (max-width: 1160px) {
.poza1
{
    border-radius:  32% 68% 70% 30% / 30% 30% 70% 70% ;
   width: 70%;
   height: 100%;
   border:solid var(--baseColor) 5px; 
}
}
@media screen and (max-width: 700px) {

    .custom-shape-divider-bottom-1679186144 {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
       z-index: -1;
       
    }
    
    .custom-shape-divider-bottom-1679186144 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        transform: rotate(180deg) translate(1px,8px);
        height: 102px;
    }
    
    .custom-shape-divider-bottom-1679186144 .shape-fill {
        fill: var(--baseColor);
    }
    
    .custome_shape {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg) translate(0px,-1px);
        z-index: -1;
        
    }
    
    .custome_shape svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 40px;
    }
    
    .custome_shape .shape-fill {
        fill: var(--baseColor);
    }
  .custom-shape-divider-top-1678998513 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 130px;
}

.logo {
    height:100px;
     width:100px;
    
}
.textLink{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: bold;
    font-size: 15px;
}


.poza1
{
   border-radius:  32% 68% 70% 30% / 30% 30% 70% 70% ;
   width: 70%;
   height: 100%;
   border:solid var(--baseColor) 5px; 
}
  }