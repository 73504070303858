body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgba(252, 197, 88, 0.16);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-color:rgba(252, 197, 88, 0.16);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
    --baseColor: rgba(28, 27, 29, 1);
    --white: #ffffff;
  }
.custom-shape-divider-bottom-1679186144 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
   z-index: -1;
   
}

.custom-shape-divider-bottom-1679186144 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    -webkit-transform: rotate(180deg) translate(1px,2px);
            transform: rotate(180deg) translate(1px,2px);
    
    height: 102px;
}

.custom-shape-divider-bottom-1679186144 .shape-fill {
    fill: rgba(28, 27, 29, 1);
    fill: var(--baseColor);
}

.custome_shape {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg) translate(0px,-1px);
            transform: rotate(180deg) translate(0px,-1px);;
    z-index: -1;
}

.custome_shape svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 160px;
}

.custome_shape .shape-fill {
    fill: rgba(28, 27, 29, 1);
    fill: var(--baseColor);
}
body{
    background-color:rgb(143 143 142);
}
.logo {
    height:120px;
     width:120px;
    
}
.poza1
{
   border-radius:  32% 68% 70% 30% / 30% 30% 70% 70% ;
   width: 600px;
   height: 600px;
   border:solid rgba(28, 27, 29, 1) 5px;
   border:solid var(--baseColor) 5px; 
}
.textLink{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: bold;
    font-size: 20px;
}
.descriere
{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 50px;
}
.headerDivider{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: visible;
    line-height: 0;
    -webkit-transform: rotate(180deg) translate(0px,1px);
            transform: rotate(180deg) translate(0px,1px);
    z-index: -1;
}

.headerDivider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 65px;
}

.headerDivider .shape-fill {
    fill: rgba(28, 27, 29, 1);
    fill: var(--baseColor);
}
@media screen and (max-width: 1160px) {
.poza1
{
    border-radius:  32% 68% 70% 30% / 30% 30% 70% 70% ;
   width: 70%;
   height: 100%;
   border:solid rgba(28, 27, 29, 1) 5px;
   border:solid var(--baseColor) 5px; 
}
}
@media screen and (max-width: 700px) {

    .custom-shape-divider-bottom-1679186144 {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
       z-index: -1;
       
    }
    
    .custom-shape-divider-bottom-1679186144 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        -webkit-transform: rotate(180deg) translate(1px,8px);
                transform: rotate(180deg) translate(1px,8px);
        height: 102px;
    }
    
    .custom-shape-divider-bottom-1679186144 .shape-fill {
        fill: rgba(28, 27, 29, 1);
        fill: var(--baseColor);
    }
    
    .custome_shape {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        -webkit-transform: rotate(180deg) translate(0px,-1px);
                transform: rotate(180deg) translate(0px,-1px);
        z-index: -1;
        
    }
    
    .custome_shape svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 40px;
    }
    
    .custome_shape .shape-fill {
        fill: rgba(28, 27, 29, 1);
        fill: var(--baseColor);
    }
  .custom-shape-divider-top-1678998513 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 130px;
}

.logo {
    height:100px;
     width:100px;
    
}
.textLink{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: bold;
    font-size: 15px;
}


.poza1
{
   border-radius:  32% 68% 70% 30% / 30% 30% 70% 70% ;
   width: 70%;
   height: 100%;
   border:solid rgba(28, 27, 29, 1) 5px;
   border:solid var(--baseColor) 5px; 
}
  }
@font-face {
    font-family: 'Arbutus';
    src:url(/static/media/Arbutus-Regular.8b03588c.ttf);
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
  @font-face {
    font-family: 'Capriola';
    src:url(/static/media/Capriola-Regular.406c90df.ttf);
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  :root {
    --bcolor:rgba(28, 27, 29, 1);
    --white: #ffffff;
    
  }
.custom-shape-divider-top-1679086423 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1679086423 svg {
    position: relative;
    display: block;
    width: calc(111% + 1.3px);
    height: 500px;
}

.custom-shape-divider-top-1679086423 .shape-fill {
    fill: #FFFFFF;
}

.containerBody
{
height:40vh;
width:100%;

display:flex;
flex-direction: row;
}

.leftSideBody
{
    style:none;
width: 50%;
height: 100%;
background-color:  rgba(28, 27, 29, 1);
background-color:  var(--bcolor);
display: flex;
flex-flow: column;
grid-gap:13.5px;
gap:13.5px;
align-items: flex-end;

}
.leftSideControl
{
    style:none;
width: 80%;
height: 100%;
background-color: rgba(28, 27, 29, 1);
background-color: var(--bcolor);
display: flex;
flex-flow: column;
grid-gap:13.5px;
gap:13.5px;
align-items:flex-start;
justify-content:center;

}
.leftSideControl>:nth-child(1)
{
   
    font-family: 'Aoboshi One';
font-style: normal;
font-weight: 400;
font-size: 1.1rem;
color: #FFFFFF;
letter-spacing: 0.06em;
}
.leftSideControl>:nth-child(2)
{style:none;
    font-family: 'Arbutus';
font-style: normal;
font-weight: 400;
font-size: 2.5rem;


color: rgba(217, 147, 51, 0.91);
}
.leftSideControl>:nth-child(3)
{
    font-family: 'Arbutus';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
   
    font-family: 'Arbutus';
    color: rgba(217, 147, 51, 0.91);
}
.leftSideControl>:nth-child(4)
{
    font-family: 'Aoboshi One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}
.rightSideBody
{
    style:none;
    width: 50%;
    height: 100%;
    background-color: rgba(28, 27, 29, 1);
    background-color: var(--bcolor);
    display: flex;
    flex-flow:row;
    justify-content: center;
    align-content: center;
    align-items:center;
    color:white;
    
}

.spatiu
{
    height:8vh;
    width: 100%;
}
.containerPagina2
{
    height:500px;
    width: 100%;
 background-color:rgba(28, 27, 29, 1);
 background-color:var(--bcolor); 
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap:50px;
    gap:50px;
}
.containerPagina2 >:nth-child(1){
    height:200px;
    width:70%;
    background-color:rgb(255, 249, 231);
    display:flex;
    flex-direction: row;
    align-items: center;
    grid-gap:10%;
    gap:10%;
    align-self: self-start;
    border-radius: 0 50px 50px 0;
    border:5px solid rgba(253, 202, 103, 1);
    border-left: 0px;
    letter-spacing: 0.06em;
}
.containerPagina2 >:nth-child(2){
    height:200px;
    width:70%;
    background-color:rgb(255, 249, 231);
    display:flex;
    flex-direction: row;
    align-items: center;
    grid-gap:10%;
    gap:10%;
    align-self: self-end;
    border-radius: 50px 0 0 50px;
    border:5px solid rgba(253, 202, 103, 1);
    border-right: 0px;
    letter-spacing: 0.06em;
}
.containerPagina2 >:nth-child(3){
    height:200px;
    width:70%;
    background-color:rgb(255, 249, 231);
    display:flex;
    flex-direction: row;
    align-items: center;
    grid-gap:10%;
    gap:10%;
    align-self: self-start;
    border-radius: 0 50px 50px 0;
    border:5px solid rgba(253, 202, 103, 1);
    border-left: 0px;
    letter-spacing: 0.06em;
}
.containerPagina2 >div >span
{
    font-size:1.3vw;
    font-family: 'Capriola';
    letter-spacing: 0.06em;
}
.containerPagina2 >div>img{
    height:120px;
    width:120px;

}
.custom-shape-divider-bottom-1679606673 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: translate(0px,1px);
            transform: translate(0px,1px);
}

.custom-shape-divider-bottom-1679606673 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
   
    height: 70px;
}

.custom-shape-divider-bottom-1679606673 .shape-fill {
    fill: rgba(28, 27, 29, 1);
    fill: var(--bcolor);
 
}

/* ///////// */

.custom-shape-divider-top-1679606894 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg) translate(0px,1px);
            transform: rotate(180deg) translate(0px,1px);
    
}

.custom-shape-divider-top-1679606894 svg {
    position: relative;
    display: block;
    width: calc(146% + 1.3px);
    height: 49px;
}

.custom-shape-divider-top-1679606894 .shape-fill {
    fill: rgba(28, 27, 29, 1);
    fill: var(--bcolor);
}
.span1 {
    display:none;
}
.span2{
    display:none;
}
@media screen and (max-width: 600px) {
    .containerBody
{
height:50vh;
width:100%;
display:flex;
flex-direction: column;
background-color: rgba(28, 27, 29, 1);
background-color: var(--bcolor);
grid-gap:5px;
gap:5px;
}
.leftSideBody
{
    style:none;
width: 100%;
height: 50%;
background-color:  rgba(28, 27, 29, 1);
background-color:  var(--bcolor);
display: flex;
flex-flow: column;
grid-gap:5px;
gap:5px;
align-items: flex-end;

}

.leftSideControl
{
    style:none;
width: 100%;
height: 100%;
background-color: rgba(28, 27, 29, 1);
background-color: var(--bcolor);
display: flex;
flex-flow: column;
grid-gap:13.5px;
gap:13.5px;
align-items:center;
justify-content:center;
}
.leftSideControl>:nth-child(1)
{  style:none;
    display: flexbox;
     margin-top:20px;
     margin-left: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-style: normal;
font-weight: 400;
font-size: 1rem;
color: #FFFFFF;
letter-spacing: 0.06em;
align-content: center;
}
.leftSideControl>:nth-child(2)
{style:none;
    font-family: 'Arbutus';
font-style: normal;
font-weight: 400;
font-size: 1.5rem;
color: rgba(217, 147, 51, 0.91);
}
.leftSideControl>:nth-child(3)
{
    font-family: 'Arbutus';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
   
    font-family: 'Arbutus';
    color: rgba(217, 147, 51, 0.91);
    
}
.leftSideControl>:nth-child(4)
{
    font-family: 'Aoboshi One';
    font-style: normal;
    line-height: 1rem;
    font-size: 1rem;
    color: #FFFFFF;
    display:none;
}
.rightSideBody
{
    style:none;
    width: 100%;
    height: 50%;
    background-color: rgba(28, 27, 29, 1);
    background-color: var(--bcolor);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items:center;
    
}
.containerPagina2 >div >span
{
    font-size:3vw;
    font-family: 'Capriola';
}
.containerPagina2 >div{
    height:200px;
    width:80%;
    background-color:rgba(252, 197, 88, 0.16);
    display:flex;
    flex-direction: row;
    align-items: center;
    grid-gap:10%;
    gap:10%;
    
}
.span1 {
    display: inline-block;
    style:none;
    font-family: 'Arbutus';
font-style: normal;
font-weight: 400;
font-size: 1.5rem;


color: rgba(217, 147, 51, 0.91);
}
.span2{
    display: inline-block;
    style:none;
    font-family: 'Aoboshi One';
font-style: normal;
font-weight: 400;
font-size: 1rem;
letter-spacing: 0.06em;
color: #FFFFFF;
}
}
.contactContainer
{
    width: 100%;
    height: 20vh;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-flow: column;
    background-color: rgba(28, 27, 29, 1);
    grid-gap:14px;
    gap:14px;
    color:white;
}
.contactContainer >span{
    font-size: 30px;
    color:white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.contactContainer>div>a {
    color:white;
}
.contactContainer>div>:hover {
    color:rgb(107, 101, 101);
}
.contactContainer>hr
{
    width: 100%;
}

.custome_shapeContact {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    -webkit-transform: rotate(180deg) translate(0px,-1px);
            transform: rotate(180deg) translate(0px,-1px);;
    z-index: -1;
}

.custome_shapeContact svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 60px;
}

.custome_shapeContact .shape-fill {
    fill: var(--baseColor);
}
@media screen and (max-width:600px) {
    .contactContainer
{
    width: 100%;
    height: 20vh;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-flow: column;
    background-color: rgba(28, 27, 29, 1);
    grid-gap:1px;
    gap:1px;
    color:white;
}
    
}
/* Footer */

:root
{
  --colorB:rgba(28, 27, 29, 1);
}
.footer {
    background-color: rgba(28, 27, 29, 1);
    background-color: var(--colorB);
    padding: 30px 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: white;
  }
  
  .footer__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer__logo {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
  
  .footer__logo img {
    max-width: 100px;
  }
  
  .footer__links {
    flex-basis: 60%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .footer__links a {
    display: inline-block;
    margin: 0 10px;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer__links a:hover {
    color: #999;
  }
  
  .footer__social {
    flex-basis: 40%;
    display: flex;
    justify-content: center;
  }
  
  .footer__social a {
    display: inline-block;
    margin: 0 10px;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer__social a:hover {
    color: #999;
  }
  
  .footer__social i {
    font-size: 20px;
    line-height: 1;
  }
  .contCopyPrivate
  {
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    align-content:center ;
    grid-gap: 10vw;
    gap: 10vw;

  }
