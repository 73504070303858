.contactContainer
{
    width: 100%;
    height: 20vh;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-flow: column;
    background-color: rgba(28, 27, 29, 1);
    gap:14px;
    color:white;
}
.contactContainer >span{
    font-size: 30px;
    color:white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.contactContainer>div>a {
    color:white;
}
.contactContainer>div>:hover {
    color:rgb(107, 101, 101);
}
.contactContainer>hr
{
    width: 100%;
}

.custome_shapeContact {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg) translate(0px,-1px);;
    z-index: -1;
}

.custome_shapeContact svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 60px;
}

.custome_shapeContact .shape-fill {
    fill: var(--baseColor);
}
@media screen and (max-width:600px) {
    .contactContainer
{
    width: 100%;
    height: 20vh;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-flow: column;
    background-color: rgba(28, 27, 29, 1);
    gap:1px;
    color:white;
}
    
}